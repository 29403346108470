<template>
  <KpiKorporatForm mode="Ubah" module="Indikator Kinerja"> </KpiKorporatForm>
</template>

<script>
import KpiKorporatForm from './form';

const KpiKorporatUpdate = {
  name: 'KpiKorporatUpdate',
  components: { KpiKorporatForm },
};

export default KpiKorporatUpdate;
</script>
